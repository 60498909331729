import debounce from 'lodash/debounce';

/**
 * This class sets up and manages resize observers for applying CSS classes to an element based on its width
 */
class ContainerQuery {
  /**
   * @example <div data-cq-observe data-cq-breakpoints="{smallClass:320,largeClass:640}"></div>
   * @param {number} [resizeDebounceTimeout] Debounce timeout for ResizeObserver callback
   * @property {function} attachResizeObserver
   * @property {function} removeResizeObserver
   */
  constructor({
    resizeDebounceTimeout = 200,
  } = {}) {
    // Initialize the Observers
    this.mutationObserver = new MutationObserver(this.mutationObserverCallback);
    this.resizeObserver = new ResizeObserver(this.resizeObserverCallback);

    // Set the timeout value
    this.RESIZE_DEBOUNCE_TIMEOUT = resizeDebounceTimeout;

    // Start watching the DOM for changes
    this.attachMutationObserver();

    // Export public methods
    // eslint-disable-next-line no-constructor-return
    return {
      attachResizeObserver: this.attachResizeObserver,
      removeResizeObserver: this.removeResizeObserver,
    };
  }

  /**
   * Configure MutationObserver and start observing root HTML element
   */
  attachMutationObserver = () => {
    const targetNode = document.querySelector('html');
    const observerConfig = {
      attributes: false,
      childList: true,
      subtree: true,
    };

    this.mutationObserver.observe(targetNode, observerConfig);
  };

  /**
   * The MutationObserver manages elements attached to ResizeObserver as DOM is initially rendered or updated
   *
   * @param {array} mutationsList Array of mutations returned by browser
   */
  mutationObserverCallback = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        const {
          addedNodes,
          removedNodes,
        } = mutation;

        // Check for added nodes
        if (addedNodes.length) {
          addedNodes.forEach((node) => {
            // Check the root node for the CQ data attribute
            const hasResizeAttribute = node.getAttribute && node.getAttribute('data-cq-observe'); // Some nodes, like text nodes don't have getAttribute() available

            if (hasResizeAttribute) {
              this.attachResizeObserver(node);
            }

            // Check child nodes for the CQ data attribute
            const matchedChildren = node.querySelectorAll && node.querySelectorAll('[data-cq-observe]'); // Some nodes, like text nodes don't have querySelectorAll() available

            if (matchedChildren && matchedChildren.length) {
              matchedChildren.forEach((childNode) => {
                this.attachResizeObserver(childNode);
              });
            }
          });
        }

        // Unattach DOM nodes from resize observer when they are destroyed
        if (removedNodes.length) {
          removedNodes.forEach((node) => {
            // Check the root node for the CQ data attribute
            if (node.getAttribute && node.getAttribute('data-cq-observe')) {
              this.removeResizeObserver(node);
            }

            // Check child nodes for the CQ data attribute
            const matchedChildren = node.querySelectorAll && node.querySelectorAll('[data-cq-observe]');

            if (matchedChildren && matchedChildren.length) {
              matchedChildren.forEach((childNode) => {
                this.removeResizeObserver(childNode);
              });
            }
          });
        }
      }
    });
  };

  /**
   * Observe DOM node with existing ResizeObserver instance
   *
   * @param {node} - DOM node to attach
   */
  attachResizeObserver = (node) => {
    // Apply classes immediately when node is added so we don't get layout thrashing
    this.addBreakpointClass(node);
    this.resizeObserver.observe(node);
  };

  /**
   * Detach DOM node from existing ResizeObserver instance
   *
   * @param {node} - DOM node to attach
   */
  removeResizeObserver = (node) => {
    this.resizeObserver.unobserve(node);
  };

  /**
   * Debounced callback function which transforms observed elements on change
   *
   * @param {array} entries - @link https://drafts.csswg.org/resize-observer/#resize-observer-entry-interface
   */
  resizeObserverCallback = debounce((entries) => {
    entries.forEach((entry) => {
      this.addBreakpointClass(entry.target);
    });
  }, this.RESIZE_DEBOUNCE_TIMEOUT);

  /**
   * Add CSS classes to DOM element based on the classes/widths defined in its data-cq-breakpoints attribute
   *
   * @param {Node} node - Array of resized DOM nodes
   */
  addBreakpointClass = (node) => {
    const {
      width,
    } = node.getBoundingClientRect();
    const breakpoints = Object.entries(JSON.parse(node.getAttribute('data-cq-breakpoints')));

    breakpoints.forEach((breakpoint, index) => {
      const [
        classname,
        breakpointWidth,
      ] = breakpoint;
      const nextWidth = (breakpoints[index + 1] && breakpoints[index + 1][1]) || (width + 1);

      if (width >= breakpointWidth && width < nextWidth) {
        node.classList.add(classname);
      } else {
        node.classList.remove(classname);
      }
    });
  };
}

if (!window._ContainerQuery) {
  window._ContainerQuery = new ContainerQuery();
}
